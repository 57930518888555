import React from "react";
import styled from "styled-components";
import { isMobile } from 'react-device-detect'

const StyledFooter = styled.footer`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledFooterInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${(props) => props.theme.topBarSize}px;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;
`;

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`;

function Nav() {
  return (
    <StyledNav>
      {isMobile ? (
        <StyledLink
          href="https://info.uniswap.org/pair/0x0b41854f5d251c12b1de6a88dd4292944f04305c"
        >
          SNP-USDT
        </StyledLink>
      ) : (
          <StyledLink
            target="_blank"
            href="https://info.uniswap.org/pair/0x0b41854f5d251c12b1de6a88dd4292944f04305c"
          >
            SNP-USDT
          </StyledLink>
        )}

      {isMobile ? (
        <StyledLink href="https://discord.gg/bNN5Ae">
          Discord
        </StyledLink>
      ) : (
          <StyledLink target="_blank" href="https://discord.gg/bNN5Ae">
            Discord
          </StyledLink>
        )}

      {isMobile ? (
        <StyledLink
          href="https://github.com/Seele-N/NervLedger-Contract"
        >
          Github
        </StyledLink>
      ) : (
          <StyledLink
            target="_blank"
            href="https://github.com/Seele-N/NervLedger-Contract"
          >
            Github
          </StyledLink>
        )}

      {isMobile ? (
        <StyledLink href="https://info.nervledger.fi">
          Info
        </StyledLink>
      ) : (
          <StyledLink target="_blank" href="https://info.nervledger.fi">
            Info
          </StyledLink>
        )}
    </StyledNav>
  );
}
export default function Footer() {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <Nav />
      </StyledFooterInner>
    </StyledFooter>
  );
}
