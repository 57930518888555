import React, { useCallback } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import Button from "../../Button";
import useModal from "../../../hooks/useModal";
import WalletProviderModal from "../../WalletProviderModal";
import AccountModal from "./AccountModal";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect'

interface AccountButtonProps {}

const AccountButton: React.FC<AccountButtonProps> = (props) => {
  const { t } = useTranslation();
  const { account,connect } = useWallet();

  const [onPresentAccountModal] = useModal(<AccountModal />);

  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal />,
    "provider"
  );

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal();
  }, [onPresentWalletProviderModal]);

  return (
    <StyledAccountButton>
      {!account ? (
        <Button
          onClick={()=>{
            isMobile?connect("injected"):handleUnlockClick()
          }
        }
          size="sm"
          text={t("Unlock Wallet")}
        />
      ) : (
        <Button
          onClick={()=>{
              onPresentAccountModal()
            }
          }
          size="sm"
          text={t("My Wallet")}
        />
      )}
    </StyledAccountButton>
  );
};

const StyledAccountButton = styled.div``;

export default AccountButton;
