import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../Button";
import Card from "../../Card";
import CardContent from "../../CardContent";
import CardIcon from "../../CardIcon";
import CardTitle from "../../CardTitle";
import Spacer from "../../Spacer";

interface WalletCardProps {
  icon: React.ReactNode;
  onConnect: () => void;
  title: string;
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <CardIcon>{icon}</CardIcon>
        <CardTitle text={title} />
        <Spacer />
        <Button onClick={onConnect} text={t("Connect")} />
      </CardContent>
    </Card>
  );
};

export default WalletCard;
