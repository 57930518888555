import React, { useMemo } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { useTranslation } from "react-i18next";

import { getContract } from "../../../utils/erc20";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import Label from "../../../components/Label";
import Value from "../../../components/Value";
import useTotalSupply from "../../../hooks/useTotalSupply";
import useSnpTotalSupply from "../../../hooks/useSnpTotalSupply";
import { contractAddresses, networkID } from "../../../constants";
import { getBalanceNumber } from "../../../utils/formatBalance";

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 568px;
  @media (max-width: 568px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: stretch;
  }
`;
const StyledBalances = styled.div`
  display: flex;
`;

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const Footnote = styled.div`
  font-size: 14px;
  padding: 8px 20px;
  color: ${(props) => props.theme.color.grey[400]};
  border-top: solid 1px ${(props) => props.theme.color.grey[300]};
`;
const FootnoteValue = styled.div`
  font-family: "Roboto Mono", monospace;
  float: right;
`;

const Balances: React.FC = () => {
  /*
  const { ethereum } = useWallet();
  const contractaddress = contractAddresses.snp[networkID];
  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, contractaddress);
  }, [ethereum, contractaddress]);
  const totalSupply = useTotalSupply(lpContract);
  */
  const totalSupply = useSnpTotalSupply();
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <Card>
        <CardContent>
          <StyledBalance>
            <div style={{ flex: 1 }}>
              <Label text={t("Total SNP Supply")} />
              <Value
                size="lg"
                value={
                  totalSupply.toNumber()
                    ? getBalanceNumber(totalSupply)
                    : "Locked"
                }
              />
            </div>
          </StyledBalance>
        </CardContent>
        <Footnote>
          {t("New rewards every day")}
          <FootnoteValue>1640 SNP</FootnoteValue>
        </Footnote>
      </Card>
    </StyledWrapper>
  );
};

export default Balances;
