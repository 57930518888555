import React, { createContext, useEffect, useState } from "react";

import { useWallet } from "use-wallet";

import { Snp } from "../../snp";

export interface SnpContext {
  snp?: typeof Snp;
}

export const Context = createContext<SnpContext>({
  snp: undefined,
});

declare global {
  interface Window {
    snpsauce: any;
  }
}

const SnpProvider: React.FC = ({ children }) => {
  const { ethereum }: { ethereum: any } = useWallet();
  const [snp, setSnp] = useState<any>();

  // @ts-ignore
  window.snp = snp;
  // @ts-ignore
  window.eth = ethereum;

  useEffect(() => {
    if (ethereum) {
      const chainId = Number(ethereum.chainId);
      console.log(chainId);
      const snpLib = new Snp(ethereum, chainId, false, {
        defaultAccount: ethereum.selectedAddress,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: "6000000",
        defaultGasPrice: "1000000000000",
        accounts: [],
        ethereumNodeTimeout: 10000,
      });
      setSnp(snpLib);
      window.snpsauce = snpLib;
    }
  }, [ethereum]);

  return <Context.Provider value={{ snp }}>{children}</Context.Provider>;
};

export default SnpProvider;
