import React from "react";
import styled from "styled-components";

interface CardIconProps {
  children?: React.ReactNode;
}

const CardIcon: React.FC<CardIconProps> = ({ children }) => (
  <StyledCardIcon>{children}</StyledCardIcon>
);

const StyledCardIcon = styled.div`
  font-size: 40px;
  height: 60px;
  width: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export default CardIcon;
