import React from "react";
import styled from "styled-components";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useWallet } from "use-wallet";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect'

import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Farm from "../Farm";
import FarmCards from "./components/FarmCards";
import Spacer from "../../components/Spacer";

import WalletProviderModal from "../../components/WalletProviderModal";
import useModal from "../../hooks/useModal";

import snplogo from "../../assets/images/snp-logo.png";
import snpfarm from "../../assets/images/snpfarm.png";

const StyledImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const Farms: React.FC = () => {
  const { path } = useRouteMatch();
  const { account, connect } = useWallet();
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />);
  const { t } = useTranslation();
  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
              <PageHeader
                icon={
                  <StyledImages>
                    <img src={snplogo} height="82" />
                    <img src={snpfarm} width="160" height="36" />
                  </StyledImages>
                }
                subtitle={t("Earn SNP tokens by staking Tokens")}
                title={t("Select Your Favorite Stakes")}
              />
              <FarmCards />
            </Route>
            <Route path={`${path}/:farmId`}>
              <Farm />
            </Route>
          </>
        ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Button
                onClick={() => {
                  isMobile ? connect("injected") : onPresentWalletProviderModal()
                }}
                text={"🔓 " + t("Unlock Wallet")}
              />
              <Spacer size="md" />
              <StyledInfo>
                ⭐️ <b>{t("Pro Tip")}</b>: {t("Investment is risky.Use at your own risk.")}
              </StyledInfo>
            </div>
          )}
      </Page>
    </Switch>
  );
};

export default Farms;
