export const white = "#FFF";
export const black = "#000";
export const backgrond = "#f1f8f2";

export const green = {
  500: "##00d1810",
};

export const red = {
  100: "#FFFDFE",
  200: "#ffc2a8",
  500: "#ffffff",
};

export const grey = {
  100: "#f7f4f2",
  200: "#ffffff",
  300: "#e2d6cf",
  400: "#aa9585",
  500: "#805e49",
  600: "#5b3926",
  700: "#118b72",
  800: "#118b80",
  900: "#ff6c15",
};
