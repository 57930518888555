import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

interface CardProps {
  children?: React.ReactNode;
  background?: string;
}

const Card: React.FC<CardProps> = ({ children,background }) => {
  const { color } = useContext(ThemeContext);
  let backgroundColor: string = color.grey[200];
  if (background){
    backgroundColor = background
  }
  
  return (
  <StyledCard 
    backgroundColor = {backgroundColor}
  >
  {children}
  </StyledCard>
  );
};

interface StyledCardProps {
  backgroundColor: string;
}

const StyledCard = styled.div<StyledCardProps>`
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.theme.color.grey[300]};
  border-radius: 24px;
  box-shadow: 0px 12px 29px -10px rgba(17, 139, 128, 0.25);
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default Card;
