import React, { useCallback, useMemo, useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import cogoToast from 'cogo-toast';
import { isMobile } from 'react-device-detect'
import BigNumber from "bignumber.js";

import snpicon from "../../../assets/images/snp.png";

import { getContract, getBalance } from "../../../utils/erc20";

import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import CardContent from "../../../components/CardContent";
import TokenInput from "../../../components/TokenInput";
import TokenValue from "../../../components/TokenValue";
import Value from "../../../components/Value";

import useTokenBalance from "../../../hooks/useTokenBalance";

import useSnp from "../../../hooks/useSnp";
import { getSnpUSDTAddress } from "../../../snp/utils";

import { getFarm } from "../../../snp";
import {
  getBalanceNumber, getFullDisplayBalance
} from "../../../utils/formatBalance";

import useBlock from "../../../hooks/useBlock";
import useAllowanceEx from "../../../hooks/useAllowanceEx";
import useAllowance from "../../../hooks/useAllowance";
import useApproveEx from "../../../hooks/useApproveEx";
import useApprove from "../../../hooks/useApprove";
import useDeposite from "../../../hooks/useDeposite";
import useWithdraw from "../../../hooks/useWithdraw";
import useStake from "../../../hooks/useStake";
import useUnstake from "../../../hooks/useUnstake";
import useStakedBalance from "../../../hooks/useStakedBalance";
import useEarnings from "../../../hooks/useEarnings";
import useHarvest from "../../../hooks/useHarvest";
import useTotallpSupply from "../../../hooks/useTotallpSupply";
import useSnpPrice from "../../../hooks/useSnpPrice"
import useTokenPrice from "../../../hooks/useTokenPrice"
import usePricePerShare from "../../../hooks/usePricePerShare"
import { networkID, contractAddresses } from "../../../constants";
import { getApy } from "../../../utils/service"

const StyledAction = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1160px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const StyledCardHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDetailContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
  justify-content: center;
  text-align: center;
`;

const StyledDetail2 = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
`;

const StyledHaverstButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 126px;
  @media (max-width: 400px) {
    justify-content: center;
    width: auto;
  }
`;

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledCardTokens = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Footnote = styled.div`
  width: 90%;
  font-size: 14px;
  padding: 8px 20px;
  color: ${(props) => props.theme.color.grey[400]};
  border-top: solid 1px ${(props) => props.theme.color.grey[300]};
`;

const StyledCardInput = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

const StyledButtonInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;


/*
function getVaultBalance(
  tokenAddress: string
) {
  const {
    account,
    ethereum,
  }: { account: string; ethereum: provider } = useWallet();
  try {
    const balance = await getBalance(ethereum, tokenAddress, account);
    return new BigNumber(balance);
  }catch (e) {
    console.log(e);
  }
  return new BigNumber(0);
}
*/

export const requestApy = () => {
  getApy().then((res: any) => {
    if (res.status === 200) {
      //console.log(res.data)
      for (let it in res.data) {
        if (res.data[it]['name'] == 'USDT') {
          console.log(res.data[it]['apyThreeDaySample'])
          return res.data[it]['apyThreeDaySample']
        }
      }
      return 0
    }
  })
  return 0
}

const Actions: React.FC = () => {
  const { t } = useTranslation();

  const [requestedApproval, setRequestedApproval] = useState(false);
  const [pendingDepositTx, setPendingDepositTx] = useState(false);
  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);
  const [pendingWithdrawTx, setPendingWithdrawTx] = useState(false);

  const {
    pid,
    contractaddress,
    allocPoint,
    decimals,
  } = getFarm("5") || {
    pid: 0,
    name: "",
  };



  const contractUSDTaddress = contractAddresses.usdt[networkID]
  const symbol = "USDT"

  const {
    account,
    ethereum,
  }: { account: string; ethereum: provider } = useWallet();

  const lpContractUSDT = useMemo(() => {
    return getContract(ethereum as provider, contractUSDTaddress);
  }, [ethereum, contractUSDTaddress]);

  const lpContractsUSDT = useMemo(() => {
    return getContract(ethereum as provider, contractaddress);
  }, [ethereum, contractaddress]);

  const block = useBlock();

  let [usdtapy, setUsdtapy] = React.useState(0)
  const fetchApy = () => {
    //e.preventDefault()
    getApy().then((res: any) => {
      if (res.status === 200) {
        //console.log(res.data)
        for (let it in res.data) {
          if (res.data[it]['name'] == 'USDT') {
            console.log(res.data[it]['apyThreeDaySample'])
            setUsdtapy(res.data[it]['apyThreeDaySample'])
          }
        }
      }
    })
  }


  useMemo(() => {
    return fetchApy();
  }, [block]);

  const snp = useSnp();
  const snpUSDTAddress = getSnpUSDTAddress(snp);

  const allowanceEx = useAllowanceEx(lpContractUSDT, snpUSDTAddress);
  const allowance = useAllowance(lpContractsUSDT);
  //const allowance = allowanceEx
  const { onApproveEx } = useApproveEx(lpContractUSDT, snpUSDTAddress);
  const { onApprove } = useApprove(lpContractsUSDT);

  const tokenBalance = useTokenBalance(contractUSDTaddress);
  const [val, setVal] = useState("0");
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );
  const handleSelectMax = useCallback(() => {
    setVal(getBalanceNumber(tokenBalance, decimals).toString());
  }, [tokenBalance, setVal]);

  const sUSDTBalance = useTokenBalance(snpUSDTAddress);
  const stakedBalance = useStakedBalance(pid).plus(sUSDTBalance);
  const [depositedVal, setDepositedVal] = useState("0");

  const handleDepositedSelectMax = useCallback(() => {
    setDepositedVal(getBalanceNumber(stakedBalance, decimals).toString());
  }, [stakedBalance, setDepositedVal]);

  const handleDepositedChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setDepositedVal(e.currentTarget.value);
    },
    [setDepositedVal]
  );

  const earnings = useEarnings(pid);

  const { onDeposite } = useDeposite();
  const { onWithdraw } = useWithdraw();

  const { onStake } = useStake(pid);
  const { onUnstake } = useUnstake(pid);

  const { onHarvest } = useHarvest(pid);

  const { color } = useContext(ThemeContext);

  const totallpSupply = useTotallpSupply();
  const pricePerShare = usePricePerShare();
  const snpPrice = useSnpPrice();
  const apy = 100 * 365 * 1640 * 0.51 * allocPoint * snpPrice.toNumber() / getBalanceNumber(totallpSupply)

  return (
    <StyledAction>
      <StyledWrapper>
        <Card>
          <CardContent>
            <StyledCardHead>
              <StyledDetailContent>
                <StyledDetail>{t("Strategy")}</StyledDetail>
                <Spacer size="sm" />
                <StyledDetail2>
                  <span>CRV-3Pool</span>
                </StyledDetail2>
              </StyledDetailContent>
              <Spacer size="sm" />
              <StyledDetailContent>
                <StyledDetail>{t("APY")}</StyledDetail>
                <Spacer size="sm" />
                <StyledDetail2>
                  {totallpSupply.toNumber() > 0 && snpPrice.toNumber() > 0 ? (
                    <span>{usdtapy.toFixed(2)}%+{apy.toFixed(2)}%</span>
                  ) : (
                      <span>--%</span>
                    )}
                </StyledDetail2>
              </StyledDetailContent>
              <Spacer size="sm" />
              <StyledDetailContent>
                <StyledDetail>{t("Earned SNP")}</StyledDetail>
                <Spacer size="sm" />
                <Value
                  value={getBalanceNumber(earnings)}
                  fontcolor={color.grey[800]}
                  size="lg"
                  decimals={6}
                />
              </StyledDetailContent>
              <Spacer size="sm" />
              <StyledHaverstButtonWrapper>
                <Button
                  size="md"
                  text={t("Harvest")}
                  backgroundcolor="#f49558"
                  disabled={pendingHarvestTx}
                  loading={pendingHarvestTx}
                  onClick={async () => {
                    setPendingHarvestTx(true);
                    const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                    try {
                      const txHash = await onHarvest();
                      console.log(txHash);
                      cogoToast.success(t('Transaction Successful'));
                    } catch (e) {
                      cogoToast.error(t('Transaction Failed'));
                    }
                    setPendingHarvestTx(false);
                    hide();
                  }
                  }
                />
              </StyledHaverstButtonWrapper>
            </StyledCardHead>
            <Spacer size="sm" />
            <StyledWrapper>
              <Card background="#f1f8f2">
                <CardContent>
                  <StyledCardContentInner>
                    <StyledCardTokens>
                      <TokenValue
                        title={t("Balance")}
                        value={getBalanceNumber(tokenBalance, decimals)}
                        symbol={symbol}
                      />
                    </StyledCardTokens>
                    <Footnote></Footnote>
                    <Spacer size="md" />
                    <StyledCardInput>
                      <TokenInput
                        value={val}
                        onSelectMax={handleSelectMax}
                        onChange={handleChange}
                        symbol={symbol}
                      />
                    </StyledCardInput>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Button
                        text="25%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.25).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="50%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.5).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="75%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.75).toString()
                          );
                        }}
                      />
                      {isMobile ? null : <Spacer size="lg" />}
                      {isMobile ? null : <Spacer size="md" />}
                      {isMobile ? null : (
                        <Button
                          text="100%"
                          size="sm"
                          buttoncolor="#118b80"
                          backgroundcolor="#118b8022"
                          onClick={() => {
                            setVal(getBalanceNumber(tokenBalance, decimals).toString());
                          }}
                        />
                      )
                      }


                    </StyledButtonInner>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Spacer size="lg" />
                      {!allowanceEx.toNumber() || !allowance.toNumber() ? (
                        <Button
                          text={t("Wallet Approve")}
                          backgroundcolor="#118b80"
                          disabled={requestedApproval}
                          loading={requestedApproval}
                          onClick={async () => {
                            setRequestedApproval(true);
                            const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                            try {
                              if (allowanceEx.toNumber() == 0) {
                                console.log(allowanceEx.toNumber());
                                const txHash = await onApproveEx();
                                console.log(txHash);
                                if (!txHash) {
                                  setRequestedApproval(false);
                                  hide();
                                  return;
                                }
                              }
                              if (allowance.toNumber() == 0) {
                                console.log(allowance.toNumber());
                                const txHash2 = await onApprove();
                                console.log(txHash2);
                                if (!txHash2) {
                                  setRequestedApproval(false);
                                  hide();
                                  return;
                                }
                              }
                              cogoToast.success(t('Transaction Successful'));
                              // user rejected tx or didn't go thru
                              //if (!txHash) {
                              //  setRequestedApproval(false);
                              //}
                            } catch (e) {
                              console.log(e);
                              cogoToast.error(t('Transaction Failed'));
                            }
                            setRequestedApproval(false);
                            hide();
                          }}
                        //onClick={handleApprove}
                        />
                      ) : (
                          <Button
                            text={t("Deposite&Stake")}
                            backgroundcolor="#118b80"
                            disabled={pendingDepositTx}
                            loading={pendingDepositTx}
                            onClick={async () => {
                              console.log(val);
                              setPendingDepositTx(true);
                              const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                              try {
                                let ref: string = "";
                                if (localStorage.getItem("refaddr")) {
                                  ref = localStorage.getItem("refaddr");
                                }
                                const txDepositeHash = await onDeposite(val);
                                console.log(txDepositeHash);

                                const balance = await getBalance(ethereum, snpUSDTAddress, account);
                                //const balancesUSDTNum = balance.dividedBy(new BigNumber(10).pow(decimals))
                                const txHash = await onStake(getFullDisplayBalance(new BigNumber(balance), decimals), ref);
                                console.log(txHash);
                                cogoToast.success(t('Transaction Successful'));
                              } catch (e) {
                                cogoToast.error(t('Transaction Failed'));
                              }
                              setPendingDepositTx(false);
                              hide();
                            }}
                          />
                        )}
                      <Spacer size="lg" />
                    </StyledButtonInner>
                  </StyledCardContentInner>
                </CardContent>
              </Card>
              <Spacer size="md" />
              <Card background="#fff9f5">
                <CardContent>
                  <StyledCardContentInner>
                    <StyledCardTokens>
                      <TokenValue
                        title={t("Acquired Shares")}
                        value={getBalanceNumber(stakedBalance, decimals)}
                        symbol={"s" + symbol}
                      />
                      {pricePerShare.toNumber() > 0 ? (
                        <TokenValue
                          title={t("(")}
                          value={getBalanceNumber(stakedBalance, decimals) * getBalanceNumber(pricePerShare, 18)}
                          symbol={symbol + ")"}
                        />
                      ) : null}
                    </StyledCardTokens>
                    <Footnote></Footnote>
                    <Spacer size="md" />
                    <StyledCardInput>
                      <TokenInput
                        value={depositedVal}
                        onSelectMax={handleDepositedSelectMax}
                        onChange={handleDepositedChange}
                        symbol={"s" + symbol}
                      />
                    </StyledCardInput>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Button
                        text="25%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.25).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="50%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.5).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="75%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.75).toString()
                          );
                        }}
                      />
                      {isMobile ? null : <Spacer size="lg" />}
                      {isMobile ? null : <Spacer size="md" />}
                      {isMobile ? null : (
                        <Button
                          text="100%"
                          size="sm"
                          buttoncolor="#f49558"
                          backgroundcolor="#f4955822"
                          onClick={() => {
                            setDepositedVal(
                              getBalanceNumber(stakedBalance, decimals).toString()
                            );
                          }}
                        />
                      )
                      }
                    </StyledButtonInner>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Spacer size="lg" />
                      <Button
                        text={t("Unstake&Withdraw")}
                        backgroundcolor="#f49558"
                        disabled={pendingWithdrawTx}
                        loading={pendingWithdrawTx}
                        onClick={async () => {
                          setPendingWithdrawTx(true);
                          const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                          try {
                            const balance = await getBalance(ethereum, snpUSDTAddress, account);
                            const balanceNum = new BigNumber(balance);
                            const balancesUSDTNum = balanceNum.dividedBy(new BigNumber(10).pow(decimals))

                            const depositedValNum = new BigNumber(depositedVal);
                            if (balancesUSDTNum.lt(depositedValNum)) {
                              const txUnstakeHash = await onUnstake(depositedValNum.minus(balancesUSDTNum).toString());
                              console.log(txUnstakeHash);
                            }
                            //const txUnstakeHash = await onUnstake(depositedVal);
                            //console.log(txUnstakeHash);

                            const txWithdrawHash = await onWithdraw(depositedVal);
                            console.log(txWithdrawHash);
                            //await onUnstake(depositedVal);
                            cogoToast.success(t('Transaction Successful'));
                          } catch (e) {
                            cogoToast.error(t('Transaction Failed'));
                          }
                          setPendingWithdrawTx(false);
                          hide();
                        }}
                      />
                      <Spacer size="lg" />
                    </StyledButtonInner>
                  </StyledCardContentInner>
                </CardContent>
              </Card>
            </StyledWrapper>
          </CardContent>
        </Card>
      </StyledWrapper>
    </StyledAction>
  );
};

export default Actions;
