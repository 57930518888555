import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";

import { getStakedPool, getSnpMasterContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useStakedPool = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0));
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);
  const block = useBlock();

  const fetchBalance = useCallback(async () => {
    const balance = await getStakedPool(snpMasterContract, pid);
    setBalance(new BigNumber(balance));
  }, [pid, snp,snpMasterContract]);

  useEffect(() => {
    if (snp) {
      fetchBalance();
    }
  }, [pid, setBalance, block, snp, snpMasterContract]);

  return balance;
};

export default useStakedPool;
