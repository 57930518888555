import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { getFarm } from "./farm";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  },
};

export const getSnpMasterAddress = (snp) => {
  return snp && snp.snpMasterAddress;
};
export const getSnpAddress = (snp) => {
  return snp && snp.snpAddress;
};

export const getSnpMasterContract = (snp) => {
  return snp && snp.contracts && snp.contracts.snpMaster;
};

export const getSnpContract = (snp) => {
  return snp && snp.contracts && snp.contracts.snp;
};

export const getSVualtUSDTContract = (snp) => {
  return snp && snp.contracts && snp.contracts.sVaultUSDT;
};

export const getSnpUSDTAddress = (snp) => {
  return snp && snp.snpUSDTAddress;
};

export const getTotalSupply = async (snpContract) => {
  try {
    const totalSupply = await snpContract.methods
      .totalSupply()
      .call();
    return new BigNumber(totalSupply);
  } catch {
    return new BigNumber(0);
  }
};

export const getPoolWeight = async (snpMasterContract, pid) => {
  const { allocPoint } = await snpMasterContract.methods.poolInfo(pid).call();
  return new BigNumber(allocPoint);
};

export const getTotallpSupply = async (snpMasterContract) => {
  const totallpSupply = await snpMasterContract.methods.totallpSupply().call();
  return new BigNumber(totallpSupply);
};

export const approve = async (
  lpContract,
  approveAddress,
  account
) => {
  return lpContract.methods
    .approve(approveAddress, ethers.constants.MaxUint256)
    .send({ from: account });
};

export const stake = async (
  snpMasterContract,
  pid,
  amount,
  refaddr,
  account
) => {
  const { decimals } = getFarm(pid.toString())
  return snpMasterContract.methods
    .deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString(),
      refaddr
    )
    .send({ from: account })
    .on("transactionHash", (tx) => {
      console.log(tx);
      return tx.transactionHash;
    });
};

export const unstake = async (snpMasterContract, pid, amount, account) => {
  const { decimals } = getFarm(pid.toString())
  return snpMasterContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString()
    )
    .send({ from: account })
    .on("transactionHash", (tx) => {
      console.log(tx);
      return tx.transactionHash;
    });
};

export const getEarned = async (snpMasterContract, pid, account) => {
  return snpMasterContract.methods.pendingSnp(pid, account).call();
};

export const harvest = async (snpMasterContract, pid, account) => {
  return snpMasterContract.methods
    .deposit(pid, "0", "")
    .send({ from: account })
    .on("transactionHash", (tx) => {
      console.log(tx);
      return tx.transactionHash;
    });
};

export const getStaked = async (snpMasterContract, pid, account) => {
  try {
    const { amount } = await snpMasterContract.methods
      .userInfo(pid, account)
      .call();
    return new BigNumber(amount);
  } catch {
    return new BigNumber(0);
  }
};

export const getStakedPool = async (snpMasterContract, pid) => {
  try {
    const { lpSupply } = await snpMasterContract.methods.poolInfo(pid).call();
    return new BigNumber(lpSupply);
  } catch {
    return new BigNumber(0);
  }
};

export const getStakedTime = async (masterChefContract, pid, account) => {
  try {
    const { depositTime } = await masterChefContract.methods
      .userInfo(pid, account)
      .call();
    return new BigNumber(depositTime);
  } catch {
    return new BigNumber(0);
  }
};

/////////////////////////////////////////////////////////////////////////////////////////
export const depositUSDT = async (
  sVaultUSDTContract,
  amount,
  account
) => {
  return sVaultUSDTContract.methods
    .deposit(
      new BigNumber(amount).times(new BigNumber(10).pow(6)).toString()
    )
    .send({ from: account })
    .on("transactionHash", (tx) => {
      console.log(tx);
      return tx.transactionHash;
    });
};

export const withdrawUSDT = async (sVaultUSDTContract, amount, account) => {
  return sVaultUSDTContract.methods
    .withdraw(
      new BigNumber(amount).times(new BigNumber(10).pow(6)).toString()
    )
    .send({ from: account })
    .on("transactionHash", (tx) => {
      console.log(tx);
      return tx.transactionHash;
    });
};

export const getPricePerShare = async (vaultContract) => {
  try {
    const pricePerFullShare = await vaultContract.methods
      .getPricePerFullShare()
      .call();
    return new BigNumber(pricePerFullShare);
  } catch {
    return new BigNumber(0);
  }
};