import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import useBlock from "./useBlock";
import { getPrice } from "../utils/service"

const useTokenPrice = (token: string) => {
  const [price, setPrice] = useState(new BigNumber(0));

  const block = useBlock();

  const fetchPrice = useCallback(async () => {
    try {
      await getPrice(token).then((res: any) => {
        if (res.status === 200) {
           console.log(res.data["USD"]);
          // setPrice(new BigNumber(res.data[token]["usd"]))
          setPrice(new BigNumber(res.data["USD"]))
        }

      }).catch((err: any) => {
        console.log(err)
      });

    } catch (e) {
      console.log(e)
    }
  }, []);

  useEffect(() => {
    fetchPrice();
    const interval = setInterval(async () => {
      fetchPrice();
    }, 30000);

    return () => clearInterval(interval);
  }, [setPrice, block]);

  return price;
};

export default useTokenPrice;