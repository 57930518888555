import React, { useContext }  from "react";
import styled, { ThemeContext } from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect'
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import Balances from "./components/Balances";
import Value from "../../components/Value";
import snplogo from "../../assets/images/snp-logo.png";
import useSnpPrice from "../../hooks/useSnpPrice"
import useTokenPrice from "../../hooks/useTokenPrice"



const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[500]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  > b {
    color: ${(props) => props.theme.color.grey[600]};
  }
`;

const StyledInfo2 = styled.h3`
  color: ${(props) => props.theme.color.grey[900]};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const StyledDetail2 = styled.div`
  color: ${(props) => props.theme.color.grey[500]};
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
`;

const StyledButtonsWrapper = styled.div`
  padding: 120px,120px;
  width: 100%;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const Home: React.FC = () => {
  const location = useLocation();
  const { color } = useContext(ThemeContext);
  const query = new URLSearchParams(location.search);
  const ref = query.get("refaddr");
  //console.log(ref);
  if (ref && ref.length > 0) {
    localStorage.setItem("refaddr", ref);
  }

  const { t } = useTranslation();

  const snpPrice = useSnpPrice();

  const seelePrice = useTokenPrice("seele");


  return (
    <Page>
      <PageHeader
        icon={<img src={snplogo} height={120} />}
        title={t("Seele & Nerv Ledger DAO is ready")}
        subtitle={t("Stake tokens to harvest your very own SNP!")}
      />
      <Container size="sm">
        {snpPrice.toNumber()>0?(
          <div>
          <StyledDetail2>
            1Seele= 
            <Value
                size="lg"
                value={seelePrice.toNumber()}
                fontcolor={color.grey[900]}
                decimals={4}
                />$
            <Spacer size="md" />
            1SNP= 
            <Value
                size="lg"
                value={snpPrice.toNumber()}
                fontcolor={color.grey[900]}
                decimals={2}
                />$
          </StyledDetail2>
          <StyledInfo2>{t("Reference price")}</StyledInfo2>
          </div>
        ):null}
        <Spacer size="sm" />
        <StyledButtonsWrapper>
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        {isMobile?(
          <Button
          size="md"
          text={t("Buy SNP")}
          backgroundcolor="#118b80"
          href="https://uniswap.consenlabs.com/#/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x795dbf627484f8248d3d6c09c309825c1563e873"
        />
        ):(
          <Button
          size="md"
          text={t("Buy SNP")}
          backgroundcolor="#118b80"
          href="https://app.uniswap.org/#/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x795dbf627484f8248d3d6c09c309825c1563e873"
        />
        )}
        
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        <Spacer size="lg" />
        </StyledButtonsWrapper>
        <Spacer size="md" />
        <Balances />
      </Container>

      <Spacer size="md" />
      <StyledInfo>
        🏆<b>{t("Pro Tip")}</b>: {t("Tip")}
      </StyledInfo>
    </Page>
  );
};

export default Home;
