import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import PageHeader from "../../components/PageHeader";
import Spacer from "../../components/Spacer";
import Actions from "./components/Actions";
import { getFarm } from "../../snp";

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;

interface RouteParams {
  farmId: string;
}

const Farm: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const { pid, name, icon } = getFarm(params.farmId) || {
    pid: 0,
    name: "",
  };
  return (
    <>
      <PageHeader
        icon={<img src={icon} style={{ height: 120 }} />}
        subtitle=""
        title={t(name) + " " + t("Pool")}
      />
      <StyledFarm>
        <Actions />
        <StyledInfo>⭐️ {t("Tip2")}</StyledInfo>
        <Spacer size="sm" />
      </StyledFarm>
    </>
  );
};

export default Farm;
