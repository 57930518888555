import React, { useState, useEffect, useContext } from "react";

import styled, { ThemeContext } from "styled-components";
import CountUp from "react-countup";

interface ValueProps {
  value: string | number;
  decimals?: number;
  fontcolor?: string;
  size?: "sm" | "md" | "lg";
}

const Value: React.FC<ValueProps> = ({ value, decimals, fontcolor, size }) => {
  const [start, updateStart] = useState(0);
  const [end, updateEnd] = useState(0);

  useEffect(() => {
    if (typeof value === "number") {
      updateStart(end);
      updateEnd(value);
    }
  }, [value]);

  const { color } = useContext(ThemeContext);
  let font_color: string = color.grey[600];
  if (fontcolor) {
    font_color = fontcolor;
  }

  let fontSize: number;
  switch (size) {
    case "sm":
      fontSize = 15;
      break;
    case "lg":
      fontSize = 20;
      break;
    case "md":
    default:
      fontSize = 16;
  }

  return (
    <StyledValue color={font_color} fontSize={fontSize}>
      {typeof value == "string" ? (
        value
      ) : (
        <CountUp
          start={start}
          end={end}
          decimals={
            decimals !== undefined ? decimals : end < 0 ? 5 : end > 1e5 ? 0 : 4
          }
          duration={1}
          separator=","
        />
      )}
    </StyledValue>
  );
};

interface StyledValueProps {
  color: string;
  fontSize: number;
}

const StyledValue = styled.div<StyledValueProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 600;
`;

export default Value;
