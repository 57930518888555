import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";

import { unstake, getSnpMasterContract } from "../snp/utils";

const useUnstake = (pid: number) => {
  const { account } = useWallet();
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await unstake(snpMasterContract, pid, amount, account);
      console.log(txHash);
    },
    [account, pid, snpMasterContract]
  );

  return { onUnstake: handleUnstake };
};

export default useUnstake;
