import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";

import { getStakedTime, getSnpMasterContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useStakedTime = (pid: number) => {
  const [depositTime, setDepositTime] = useState(new BigNumber(0));
  const { account }: { account: string } = useWallet();
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);
  const block = useBlock();

  const fetchStakedTime = useCallback(async () => {
    const depositTime = await getStakedTime(snpMasterContract, pid, account);
    setDepositTime(new BigNumber(depositTime));
  }, [account, pid, snp,snpMasterContract]);

  useEffect(() => {
    if (account && snp) {
      fetchStakedTime();
    }
  }, [account, pid, setDepositTime, block, snp, snpMasterContract]);

  return depositTime;
};

export default useStakedTime;
