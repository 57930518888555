import React, { Component } from 'react'
import { useTranslation } from "react-i18next";

interface Props {
  endTime: string;
  dayTxt: string;
  HourTxt: string;
  MinuteTxt:string;
  SecondTxt:string;
  endTimeFun?: Function;
}
interface State {
  day: string | number;
  hour: string | number;
  minute: string | number;
  second: string | number;
}
export default class CountDown extends Component<Props, State> {
  timer: number;
  constructor(props:any) {
    super(props);
    this.state = {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0
    }
  }
  componentDidMount() {
    if (this.props.endTime) {
      let endTime = this.props.endTime.replace(/-/g, "/");
      this.countFun(endTime);
    }
  }

  //组件卸载取消倒计时
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  countFun = (time:string) => {
    let end_time = new Date(time).getTime(),
      sys_second = end_time - new Date().getTime();

    // 将倒计时方法抽出，立即触发第一次倒计时
    const startTimer = () => {
      //防止倒计时出现负数
      if (sys_second > 1000) {
        sys_second -= 1000;
        let day = Math.floor((sys_second / 1000 / 3600) / 24);
        let hour = Math.floor((sys_second / 1000 / 3600) % 24);
        let minute = Math.floor((sys_second / 1000 / 60) % 60);
        let second = Math.floor(sys_second / 1000 % 60);
        this.setState({
          day: day,
          hour: hour < 10 ? "0" + hour : hour,
          minute: minute < 10 ? "0" + minute : minute,
          second: second < 10 ? "0" + second : second
        })
      } else {
        clearInterval(this.timer);
        //倒计时结束时触发父组件的方法
        this.props.endTimeFun && this.props.endTimeFun();
      }
      return startTimer
    }

    this.timer = setInterval(startTimer(), 1000);
  }

  render() {
    const { day, hour, minute, second } = this.state;
    return (
      <>
        {day ? `${day}${this.props.dayTxt}` : ''}
        {hour && hour !== '00' ? `${hour}${this.props.HourTxt}` : ''}
        {minute == '0' ? '00' : minute+`${this.props.MinuteTxt}`}
        {second == '0' ? '00' : second+`${this.props.SecondTxt}`}
      </>
    )
  }
}