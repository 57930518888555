import React, { useCallback, useMemo, useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useParams } from "react-router-dom";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import cogoToast from 'cogo-toast';
import { isMobile } from 'react-device-detect'

import snpicon from "../../../assets/images/snp.png";

import { getContract } from "../../../utils/erc20";

import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import CardContent from "../../../components/CardContent";
import TokenInput from "../../../components/TokenInput";
import TokenValue from "../../../components/TokenValue";
import Value from "../../../components/Value";

import useTokenBalance from "../../../hooks/useTokenBalance";

import { getFarm } from "../../../snp";
import {
  getBalanceNumber,getFullDisplayBalance
} from "../../../utils/formatBalance";

import useAllowance from "../../../hooks/useAllowance";
import useApprove from "../../../hooks/useApprove";
import useStake from "../../../hooks/useStake";
import useUnstake from "../../../hooks/useUnstake";
import useStakedBalance from "../../../hooks/useStakedBalance";
import useEarnings from "../../../hooks/useEarnings";
import useHarvest from "../../../hooks/useHarvest";
import useStakedTime from "../../../hooks/useStakedTime";
import useTotallpSupply from "../../../hooks/useTotallpSupply";
import useSnpPrice from "../../../hooks/useSnpPrice"
import useTokenPrice from "../../../hooks/useTokenPrice"

const StyledAction = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1160px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const StyledCardHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDetailContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
  justify-content: center;
  text-align: center;
`;

const StyledDetail2 = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
`;

const StyledHaverstButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 126px;
  @media (max-width: 400px) {
    justify-content: center;
    width: auto;
  }
`;

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledCardTokens = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Footnote = styled.div`
  width: 90%;
  font-size: 14px;
  padding: 8px 20px;
  color: ${(props) => props.theme.color.grey[400]};
  border-top: solid 1px ${(props) => props.theme.color.grey[300]};
`;

const StyledCardInput = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

const StyledButtonInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

interface RouteParams {
  farmId: string;
}

function unlockDate(
  depositTime: number,
  lockPeriod: number,
  unlockPeriod: number
) {
  const now = Date.now();
  let unlocktime: number = depositTime + lockPeriod;
  while (unlocktime + unlockPeriod < now) {
    unlocktime = unlocktime + lockPeriod;
  }
  let unlocktimePeriod: number = unlocktime + unlockPeriod;
  return (
    new Date(unlocktime).toLocaleString() +
    "--" +
    new Date(unlocktimePeriod).toLocaleString()
  );
}

const Actions: React.FC = () => {
  const { t } = useTranslation();

  const [requestedApproval, setRequestedApproval] = useState(false);
  const [pendingDepositTx, setPendingDepositTx] = useState(false);
  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);
  const [pendingWithdrawTx, setPendingWithdrawTx] = useState(false);

  const params = useParams<RouteParams>();
  const {
    pid,
    symbol,
    contractaddress,
    lockPeriod,
    unlockPeriod,
    allocPoint,
    decimals,
  } = getFarm(params.farmId) || {
    pid: 0,
    name: "",
  };

  const { ethereum } = useWallet();
  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, contractaddress);
  }, [ethereum, contractaddress]);
  const allowance = useAllowance(lpContract);
  const { onApprove } = useApprove(lpContract);

  const tokenBalance = useTokenBalance(contractaddress);
  const [val, setVal] = useState("0");
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );
  const handleSelectMax = useCallback(() => {
    setVal(getFullDisplayBalance(tokenBalance, decimals));
  }, [tokenBalance, setVal]);

  const stakedBalance = useStakedBalance(pid);
  const [depositedVal, setDepositedVal] = useState("0");

  const handleDepositedSelectMax = useCallback(() => {
    setDepositedVal(getFullDisplayBalance(stakedBalance, decimals));
  }, [stakedBalance, setDepositedVal]);

  const handleDepositedChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setDepositedVal(e.currentTarget.value);
    },
    [setDepositedVal]
  );

  const earnings = useEarnings(pid);

  const handleApprove = useCallback(async () => {
    setRequestedApproval(true);
    const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
    try {
      const txHash = await onApprove();
      console.log(txHash);
      cogoToast.success(t('Transaction Successful'));
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false);
      }
    } catch (e) {
      console.log(e);
      cogoToast.error(t('Transaction Failed'));
    }
    setRequestedApproval(false);
    hide();
  }, [onApprove, setRequestedApproval]);

  const { onStake } = useStake(pid);
  const { onUnstake } = useUnstake(pid);

  const { onHarvest } = useHarvest(pid);

  const depositTime = useStakedTime(pid);

  const { color } = useContext(ThemeContext);

  const totallpSupply = useTotallpSupply();
  const snpPrice = useSnpPrice();
  const seelePrice = useTokenPrice("seele");
  const apyseele = 100 * 365 * 1640 * 0.51 * allocPoint * snpPrice.toNumber() / getBalanceNumber(totallpSupply) / seelePrice.toNumber()
  const apyusdt = 100 * 365 * 1640 * 0.51 * allocPoint * snpPrice.toNumber() / getBalanceNumber(totallpSupply)
  const apysnp = 100 * 365 * 1640 * 0.51 * allocPoint  / getBalanceNumber(totallpSupply) 

  return (
    <StyledAction>
      {lockPeriod && unlockPeriod > 0 && depositTime.toNumber() && stakedBalance.toNumber() ? (
        <StyledDetailContent>
          <StyledDetail>
            {t("Deposite Time")}:
            {new Date(
              depositTime.multipliedBy(1000).toNumber()
            ).toLocaleString()}
          </StyledDetail>
          <StyledDetail2>
            {t("Unlock Time")}:
            {unlockDate(
              depositTime.multipliedBy(1000).toNumber(),
              lockPeriod * 1000,
              unlockPeriod * 1000
            )}
          </StyledDetail2>
        </StyledDetailContent>
      ) : (
          <div></div>
        )}

      <StyledWrapper>
        <Card>
          <CardContent>
            <StyledCardHead>
              <img src={snpicon} style={{ height: 50 }} />
              <Spacer size="sm" />
              <StyledDetailContent>
                <StyledDetail>{t("APY")}</StyledDetail>
                <Spacer size="sm" />
                <StyledDetail2>
                  {seelePrice.toNumber() > 0 && totallpSupply.toNumber() > 0 && snpPrice.toNumber() > 0 ? (
                    <span>{pid >= 5 ? (
                      pid == 5?(
                      apyusdt.toFixed(2)
                      ):(
                        apysnp.toFixed(2)
                      )
                    ) : (
                        apyseele.toFixed(2)
                      )
                    }%</span>
                  ) : (
                      <span>--%</span>
                    )}
                </StyledDetail2>
              </StyledDetailContent>
              <Spacer size="sm" />
              <StyledDetailContent>
                <StyledDetail>{t("Earned SNP")}</StyledDetail>
                <Spacer size="sm" />
                <Value
                  value={getBalanceNumber(earnings)}
                  fontcolor={color.grey[800]}
                  size="lg"
                  decimals={6}
                />
              </StyledDetailContent>
              <Spacer size="sm" />
              <StyledHaverstButtonWrapper>
                <Button
                  size="md"
                  text={t("Harvest")}
                  backgroundcolor="#f49558"
                  disabled={pendingHarvestTx}
                  loading={pendingHarvestTx}
                  onClick={async () => {
                    setPendingHarvestTx(true);
                    const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                    try {
                      const txHash = await onHarvest();
                      console.log(txHash);
                      cogoToast.success(t('Transaction Successful'));
                    } catch (e) {
                      cogoToast.error(t('Transaction Failed'));
                    }
                    setPendingHarvestTx(false);
                    hide();
                  }
                  }
                />
              </StyledHaverstButtonWrapper>
            </StyledCardHead>
            <Spacer size="sm" />
            <StyledWrapper>
              <Card background="#f1f8f2">
                <CardContent>
                  <StyledCardContentInner>
                    <StyledCardTokens>
                      <TokenValue
                        title={t("Balance")}
                        value={getBalanceNumber(tokenBalance, decimals)}
                        symbol={symbol}
                      />
                    </StyledCardTokens>
                    <Footnote></Footnote>
                    <Spacer size="md" />
                    <StyledCardInput>
                      <TokenInput
                        value={val}
                        onSelectMax={handleSelectMax}
                        onChange={handleChange}
                        symbol={symbol}
                      />
                    </StyledCardInput>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Button
                        text="25%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.25).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="50%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.5).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="75%"
                        size="sm"
                        buttoncolor="#118b80"
                        backgroundcolor="#118b8022"
                        onClick={() => {
                          setVal(
                            (getBalanceNumber(tokenBalance, decimals) * 0.75).toString()
                          );
                        }}
                      />
                      {isMobile ? null : <Spacer size="lg" />}
                      {isMobile ? null : <Spacer size="md" />}
                      {isMobile ? null : (
                        <Button
                          text="100%"
                          size="sm"
                          buttoncolor="#118b80"
                          backgroundcolor="#118b8022"
                          onClick={() => {
                            setVal(getFullDisplayBalance(tokenBalance, decimals));
                          }}
                        />
                      )
                      }


                    </StyledButtonInner>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Spacer size="lg" />
                      {!allowance.toNumber() ? (
                        <Button
                          text={t("Wallet Approve")}
                          backgroundcolor="#118b80"
                          disabled={requestedApproval}
                          loading={requestedApproval}
                          onClick={handleApprove}
                        />
                      ) : (
                          <Button
                            text={t("Deposite")}
                            backgroundcolor="#118b80"
                            disabled={pendingDepositTx}
                            loading={pendingDepositTx}
                            onClick={async () => {
                              console.log(val);
                              setPendingDepositTx(true);
                              const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                              try {
                                let ref: string = "";
                                if (localStorage.getItem("refaddr")) {
                                  ref = localStorage.getItem("refaddr");
                                }
                                const txHash = await onStake(val, ref);
                                console.log(txHash);
                                cogoToast.success(t('Transaction Successful'));
                              } catch (e) {
                                console.log(e);
                                cogoToast.error(t('Transaction Failed'));
                              }
                              setPendingDepositTx(false);
                              hide();
                            }}
                          />
                        )}
                      <Spacer size="lg" />
                    </StyledButtonInner>
                  </StyledCardContentInner>
                </CardContent>
              </Card>
              <Spacer size="md" />
              <Card background="#fff9f5">
                <CardContent>
                  <StyledCardContentInner>
                    <StyledCardTokens>
                      <TokenValue
                        title={t("Deposited")}
                        value={getBalanceNumber(stakedBalance, decimals)}
                        symbol={symbol}
                      />
                    </StyledCardTokens>
                    <Footnote></Footnote>
                    <Spacer size="md" />
                    <StyledCardInput>
                      <TokenInput
                        value={depositedVal}
                        onSelectMax={handleDepositedSelectMax}
                        onChange={handleDepositedChange}
                        symbol={symbol}
                      />
                    </StyledCardInput>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Button
                        text="25%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.25).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="50%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.5).toString()
                          );
                        }}
                      />
                      <Spacer size="lg" />
                      <Spacer size="md" />
                      <Button
                        text="75%"
                        size="sm"
                        buttoncolor="#f49558"
                        backgroundcolor="#f4955822"
                        onClick={() => {
                          setDepositedVal(
                            (getBalanceNumber(stakedBalance, decimals) * 0.75).toString()
                          );
                        }}
                      />
                      {isMobile ? null : <Spacer size="lg" />}
                      {isMobile ? null : <Spacer size="md" />}
                      {isMobile ? null : (
                        <Button
                          text="100%"
                          size="sm"
                          buttoncolor="#f49558"
                          backgroundcolor="#f4955822"
                          onClick={() => {
                            setDepositedVal(
                              getFullDisplayBalance(stakedBalance, decimals)
                            );
                          }}
                        />
                      )
                      }
                    </StyledButtonInner>
                    <Spacer size="md" />
                    <StyledButtonInner>
                      <Spacer size="lg" />
                      <Button
                        text={t("Withdraw")}
                        backgroundcolor="#f49558"
                        disabled={pendingWithdrawTx}
                        loading={pendingWithdrawTx}
                        onClick={async () => {
                          setPendingWithdrawTx(true);
                          const { hide } = cogoToast.loading(t("Waiting For Transaction"), { hideAfter: 0 });
                          try {
                            await onUnstake(depositedVal);
                            cogoToast.success(t('Transaction Successful'));
                          } catch (e) {
                            cogoToast.error(t('Transaction Failed'));
                          }
                          setPendingWithdrawTx(false);
                          hide();
                        }}
                      />
                      <Spacer size="lg" />
                    </StyledButtonInner>
                  </StyledCardContentInner>
                </CardContent>
              </Card>
            </StyledWrapper>
          </CardContent>
        </Card>
      </StyledWrapper>
    </StyledAction>
  );
};

export default Actions;
