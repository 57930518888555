import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { UseWalletProvider } from "use-wallet";
import ModalsProvider from "../contexts/Modals";
import SnpProvider from "../contexts/SnpProvider";
import TransactionProvider from '../contexts/Transactions'
import Header from "../components/Header";
import theme from "../theme";
import Home from "./Home";
import Farms from "./Farms";
import Vault from "./Vault";

import { networkID, chainConfig } from "../constants";


const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={networkID} // 1 mainnet 3 ropsten;4 rinkeby
        connectors={{
          walletconnect: { rpcUrl: chainConfig.rpc[networkID] },
          // https://mainnet.eth.aragon.network/ https://ropsten.eth.aragon.network/ https://rinkeby.eth.aragon.network/
        }}
      >
        <SnpProvider>
          <TransactionProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </TransactionProvider>
        </SnpProvider>
      </UseWalletProvider>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <Providers>
      <Router>
        <Header></Header>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/farms">
            <Farms />
          </Route>
          <Route path="/vault" exact>
            <Vault />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Providers>
  );
};

export default App;
