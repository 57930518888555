import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { Contract } from "web3-eth-contract";

import useSnp from "./useSnp";
import { getSnpMasterAddress } from "../snp/utils";
import { getAllowance } from "../utils/erc20";

const useAllowance = (lpContract: Contract) => {
  const [allowance, setAllowance] = useState(new BigNumber(0));
  const { account }: { account: string; ethereum: provider } = useWallet();
  const snp = useSnp();
  const snpMasterAddress = getSnpMasterAddress(snp);

  const fetchAllowance = useCallback(async () => {
    const allowance = await getAllowance(lpContract, snpMasterAddress, account);
    setAllowance(new BigNumber(allowance));
  }, [account, snpMasterAddress, lpContract]);

  useEffect(() => {
    if (account && snpMasterAddress && lpContract) {
      fetchAllowance();
    }
    let refreshInterval = setInterval(fetchAllowance, 10000);
    return () => clearInterval(refreshInterval);
  }, [account, snpMasterAddress, lpContract]);

  return allowance;
};

export default useAllowance;
