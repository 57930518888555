import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "../../../components/Button";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import Spacer from "../../../components/Spacer";
import CardIcon from "../../../components/CardIcon";
import { getFarms } from "../../../snp";
import { Farm } from "../../../snp/types";

import useStakedPool from "../../../hooks/useStakedPool";
import useStakedBalance from "../../../hooks/useStakedBalance";
import useTotallpSupply from "../../../hooks/useTotallpSupply";
import useSnpPrice from "../../../hooks/useSnpPrice"
import useTokenPrice from "../../../hooks/useTokenPrice"

import Value from "../../../components/Value";
import CountDown from "../../../components/CountDown";

import { getBalanceNumber } from "../../../utils/formatBalance";

const StyledCards = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`;


const stakePools = getFarms();

const rows = stakePools.reduce<Farm[][]>((sum, item, index, arr) => {
  const curStep = arr.slice(sum.length * 3, (sum.length + 1) * 3);
  if (!!curStep.length) {
    sum.push(curStep);
  }
  return sum;
}, []);

const FarmCards: React.FC = () => {
  return (
    <StyledCards>
      {stakePools.map((farm, j) => (
        <React.Fragment key={j}>
          <FarmCard farm={farm} />
        </React.Fragment>
      ))}
    </StyledCards>
  );
};

const StyledCardWrapper = styled.div`
  display: flex;
  width: calc((1160px - 16px * 6) / 3);
  margin: 16px;
  position: relative;
`;

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h4`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 24px;
  font-weight: 700;
  margin: ${(props) => props.theme.spacing[2]}px 0 0;
  padding: 0;
`;

const StyledDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]}px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
`;

const StyledDetail2 = styled.div`
  color: ${(props) => props.theme.color.grey[800]};
  font-size: 20px;
  font-weight: 700;
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
`;

const StyledDetail3 = styled.div`
color: ${(props) => props.theme.color.grey[900]};
font-size: 16px;
font-weight: 600;
align-items: center;
display: flex;
flex-direction: row;
text-align: center;
justify-content: center;
`;

const StyledCardContent2 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const StyledInsight = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fffdfa;
  color: #aa9584;
  width: 100%;
  margin-top: 12px;
  line-height: 32px;
  font-size: 13px;
  border: 1px solid #e6dcd5;
  text-align: center;
  padding: 0 12px;
  margin: 22px;
`;

const RewardValue = styled.div`
  font-family: "Roboto Mono", monospace;
  position: absolute;
  right: 0;
  margin-right: 22px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.color.grey[900]};
`;

interface FarmCardProps {
  farm: Farm;
}

const FarmCard: React.FC<FarmCardProps> = ({ farm }) => {
  const stakedBalance = useStakedPool(farm.pid);
  const { color } = useContext(ThemeContext);
  const { t } = useTranslation();
  const userstakedBalance = useStakedBalance(farm.pid)
  const totallpSupply = useTotallpSupply();
  const snpPrice = useSnpPrice();
  const seelePrice = useTokenPrice("seele");
  const apyseele = 100 * 365 * 1640 * 0.51 * farm.allocPoint * snpPrice.toNumber() / getBalanceNumber(totallpSupply) / seelePrice.toNumber()
  const apyusdt = 100 * 365 * 1640 * 0.51 * farm.allocPoint * snpPrice.toNumber() / getBalanceNumber(totallpSupply)
  const apysnp = 100 * 365 * 1640 * 0.51 * farm.allocPoint  / getBalanceNumber(totallpSupply) 
  return (
    <StyledCardWrapper>
      <Card>
        <CardContent>
          <StyledContent>
            <CardIcon>
              <img src={farm.icon} style={{ height: 80 }} />
              {farm.allocPoint > 1 && farm.pid < 5 ? (
                <RewardValue>x{farm.allocPoint}</RewardValue>
              ) : (
                  <div></div>
                )}
            </CardIcon>
            <StyledTitle>{t(farm.name)}</StyledTitle>
            <StyledDetails>
              {farm.pid <= 6?(
                <StyledDetail>{t("Staked")}</StyledDetail>
                ):(
                <div>
                  <StyledDetail>{t("Open Soon")}</StyledDetail>
                  <CountDown endTime={'2022-04-15 16:00:00'} dayTxt={t('Day')} HourTxt={t('H')} MinuteTxt={t('M')} SecondTxt={t('S')}/>
                </div>
                )}
              
              <Spacer size="sm" />
              <StyledDetail2>
                {t("Total Deposited")}:<Value
                  value={getBalanceNumber(stakedBalance, farm.decimals)}
                  fontcolor={color.grey[800]}
                  size="lg"
                  decimals={2}
                />
              </StyledDetail2>
              <StyledDetail2>
                <StyledDetail3>{t("Your Deposited")}:</StyledDetail3>
                <Value
                  value={getBalanceNumber(userstakedBalance, farm.decimals)}
                  fontcolor={color.grey[900]}
                  decimals={2}
                />
              </StyledDetail2>
            </StyledDetails>

            <StyledCardContent2>
              <StyledInsight>
                <span>{t("APY")}</span>
                {seelePrice.toNumber() > 0 && totallpSupply.toNumber() > 0 && snpPrice.toNumber() > 0 ? (
                  <span>{farm.pid >= 5 ? (
                    farm.pid == 5?(
                      apyusdt.toFixed(2)
                      ):(
                        apysnp.toFixed(2)
                      )
                  ) : (
                      apyseele.toFixed(2)
                    )
                  }%</span>
                ) : (
                    <span>--%</span>
                  )}
              </StyledInsight>

              <Button
                text={t("Deposit to mine")}
                size="md"
                to={`/farms/${farm.id}`}
                disabled={farm.pid>=7?true:false}
              ></Button>
            </StyledCardContent2>
          </StyledContent>
        </CardContent>
      </Card>
    </StyledCardWrapper>
  );
};
export default FarmCards;
