import Web3 from "web3";
import { Contracts } from "./lib/contracts.js";
import { contractAddresses } from "../constants";

export class Snp {
  constructor(provider, networkId, testing, options) {
    var realProvider;

    if (typeof provider === "string") {
      if (provider.includes("wss")) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      } else {
        realProvider = new Web3.providers.HttpProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      }
    } else {
      realProvider = provider;
    }

    this.web3 = new Web3(realProvider);

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount;
    }

    this.contracts = new Contracts(realProvider, networkId, this.web3, options);
    this.seeleAddress = contractAddresses.seele[networkId];
    this.snpAddress = contractAddresses.snp[networkId];
    this.snpMasterAddress = contractAddresses.snpmaster[networkId];
    this.snpUSDTAddress = contractAddresses.susdt[networkId];
  }
}
