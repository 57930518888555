import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import gql from 'graphql-tag'

export const client = new ApolloClient({
    link: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
    }),
    cache: new InMemoryCache(),
  })


export const SNP_PAIR_DATA  = (pairAddress:string) => {
    const queryString =`
    query pairs {
        pairs(where: {id: "${pairAddress}"}){
            id
            reserve1
            token1Price
        }
    }
    `
    return gql(queryString)
}