
export const networkID = 1; // 1 mainnet 3 ropsten;4 rinkeby

export const chainConfig = {
  chainID: {
    1: 1,
    3: 1,
    4: 1,
  },
  rpc: {
    1: "https://mainnet.eth.aragon.network/",
    3: "https://ropsten.eth.aragon.network/",
    4: "https://rinkeby.eth.aragon.network/",
  },
};

export const contractAddresses = {
  seele: {
    1: "0xB1e93236ab6073fdAC58adA5564897177D4bcC43", // mainnet
    3: "0x3b4bdd7d72b7851202f058ea9c5f25cde263612d", // ropsten
    4: "0x3b4bdd7d72b7851202f058ea9c5f25cde263612d", // rinkeby
  },
  snpmaster: {
    1: "0x7C5592faD8031e62318DAbD81e2211E50A231Ffb", // 作废 0xF537bB17a73f610690b8f69e8dFA9658d5446D4d
    3: "0x171De54687f134084C5359929DBA9069Cab64e9C",
    4: "0x3b4bdd7d72b7851202f058ea9c5f25cde263612d",
  },
  snp: {
    1: "0x795dBF627484F8248D3d6c09c309825c1563E873", // 作废 0x24f5f44cdde963ad666ef651de1a63919efb791c
    3: "0xaC794ABaa6063c144289531380a62489c0dD86b2",
    4: "0x3b4bdd7d72b7851202f058ea9c5f25cde263612d",
  },
  usdt: {
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // mainnet
    3: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // ropsten
    4: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // rinkeby
  },
  susdt: {
    1: "0x6a71f460d90eef13980a00c98481bbfa8316a51b",
    3: "0x6a71f460d90eef13980a00c98481bbfa8316a51b",
    4: "0x6a71f460d90eef13980a00c98481bbfa8316a51b",
  },
};
