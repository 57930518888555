import React from "react";

import styled from "styled-components";
import Spacer from "../Spacer";
import Value from "../Value";

interface ValueProps {
  title: string;
  value: string | number;
  symbol?: string;
  decimals?: number;
  align?: "flex-start" | "flex-end" | "center";
}

const TokenValue: React.FC<ValueProps> = ({ title, value, symbol, decimals, align }) => {
  let justifyContent: string = "flex-start";
  if (align) {
    justifyContent = align;
  }
  return (
    <StyledContentInner justifyContent={justifyContent}>
      <StyledTitle>{title}</StyledTitle>
      <Spacer size="sm" />
      <Value value={value} decimals={decimals} />
      <Spacer size="sm" />
      <StyledTitle>{symbol}</StyledTitle>
    </StyledContentInner>
  );
};

interface StyledContentInnerProps {
  justifyContent: string;
}

const StyledContentInner = styled.div<StyledContentInnerProps>`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent};
`;

const StyledTitle = styled.div`
  font-family: "Roboto Mono", monospace;
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 16px;
  font-weight: 500;
`;

export default TokenValue;
