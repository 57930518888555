import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";

import { depositUSDT, getSVualtUSDTContract } from "../snp/utils";

const useDeposite = () => {
  const { account } = useWallet();
  const snp = useSnp();

  const handleDeposite = useCallback(
    async (amount: string) => {
      const txHash = await depositUSDT(
        getSVualtUSDTContract(snp),
        amount,
        account
      );
      console.log(txHash);
    },
    [account, snp]
  );

  return { onDeposite: handleDeposite };
};

export default useDeposite;
