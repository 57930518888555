import { useCallback } from "react";

import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { Contract } from "web3-eth-contract";

import useSnp from "./useSnp";
import { getSnpMasterAddress } from "../snp/utils";
import { approve } from "../snp/utils";

const useApprove = (lpContract: Contract) => {
  const { account }: { account: string; ethereum: provider } = useWallet();
  const snp = useSnp();
  const snpMasterAddress = getSnpMasterAddress(snp);

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, snpMasterAddress, account);
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, lpContract, snpMasterAddress]);

  return { onApprove: handleApprove };
};

export default useApprove;
