import React, { useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import IconLoading from '../IconLoading' 
import { isMobile } from 'react-device-detect'

interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  text?: string;
  to?: string;
  variant?: "default" | "secondary" | "tertiary";
  buttoncolor?: string;
  backgroundcolor?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  variant,
  buttoncolor,
  backgroundcolor,
  loading,
}) => {
  const { color, spacing } = useContext(ThemeContext);

  let buttonColor: string;
  let backgroundColor: string;
  backgroundColor = color.grey[800];
  if (backgroundcolor) {
    backgroundColor = backgroundcolor;
  }
  switch (variant) {
    case "secondary":
      buttonColor = color.grey[500];
      break;
    case "default":
    default:
      buttonColor = color.primary.main;
  }
  if (buttoncolor) {
    buttonColor = buttoncolor;
  }

  let boxShadow: string;
  let buttonSize: number;
  let buttonPadding: number;
  let fontSize: number;

  switch (size) {
    case "sm":
      boxShadow = `4px 4px 8px ${color.grey[300]},
        -8px -8px 16px ${color.grey[100]}FF;`;
      buttonPadding = spacing[3];
      buttonSize = 38;
      fontSize = 15;
      break;
    case "lg":
      boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px ${color.grey[100]}ff;`;
      buttonPadding = spacing[4];
      buttonSize = 72;
      fontSize = 16;
      break;
    case "md":
    default:
      boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px -2px ${color.grey[100]}ff;`;
      buttonPadding = spacing[4];
      buttonSize = 56;
      fontSize = 16;
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>;
    } else if (href) {
      return (
        <div>
        {isMobile?(
          <StyledExternalLink href={href}>
          {text}
          </StyledExternalLink>
        ):(
          <StyledExternalLink href={href} target="__blank">
          {text}
          </StyledExternalLink>
        )}
        
        </div>
      );
    } else {
      return text;
    }
  }, [href, text, to]);

  return (
    <StyledButton
      boxShadow={boxShadow}
      color={buttonColor}
      backgroundcolor={backgroundColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
    >
      {loading && <IconLoading/>}
      {children}
      {ButtonChild}
    </StyledButton>
  );
};

interface StyledButtonProps {
  boxShadow: string;
  color: string;
  backgroundcolor: string;
  disabled?: boolean;
  fontSize: number;
  padding: number;
  size: number;
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: ${(props) => props.backgroundcolor};
  border: 0;
  border-radius: 12px;
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => (!props.disabled ? props.color : `${props.color}55`)};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  height: ${(props) => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : "none")};
  width: 100%;
  &:hover {
    background-color: ${(props) => `${props.backgroundcolor}e0`};
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
  height: 50px;
`;

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

export default Button;
