import { useCallback, useEffect, useState } from "react";
import { provider } from "web3-core";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";

import { getEarned, getSnpMasterContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useEarnings = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0));
  const { account }: { account: string } = useWallet();
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);
  const block = useBlock();

  const fetchBalance = useCallback(async () => {
    const balance = await getEarned(snpMasterContract, pid, account);
    setBalance(new BigNumber(balance));
  }, [account, snpMasterContract, snp]);

  useEffect(() => {
    if (account && snpMasterContract && snp) {
      fetchBalance();
    }
  }, [account, block, snpMasterContract, setBalance, snp]);

  return balance;
};

export default useEarnings;
