import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";

import { getStaked, getSnpMasterContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useStakedBalance = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0));
  const { account }: { account: string } = useWallet();
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);
  const block = useBlock();

  const fetchBalance = useCallback(async () => {
    const balance = await getStaked(snpMasterContract, pid, account);
    setBalance(new BigNumber(balance));
  }, [account, pid, snp,snpMasterContract]);

  useEffect(() => {
    if (account && snp) {
      fetchBalance();
    }
  }, [account, pid, setBalance, block, snp, snpMasterContract]);

  return balance;
};

export default useStakedBalance;
