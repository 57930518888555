import React from "react";
import styled from "styled-components";
import Container from "../Container";
import Logo from "../Logo";
import Nav from "./components/Nav";
import AccountButton from "./components/AccountButton";

const StyledHeader = styled.div``;

const StyledHeaderInner = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;
`;

const StyledLogoWrapper = styled.div`
  width: 260px;
  @media (max-width: 400px) {
    width: auto;
  }
`;

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 156px;
  @media (max-width: 400px) {
    justify-content: center;
    width: auto;
  }
`;

export default function Header() {
  return (
    <StyledHeader>
      <Container size="lg">
        <StyledHeaderInner>
          <StyledLogoWrapper>
            <Logo />
          </StyledLogoWrapper>
          <Nav />
          <StyledAccountButtonWrapper>
            <AccountButton />
          </StyledAccountButtonWrapper>
        </StyledHeaderInner>
      </Container>
    </StyledHeader>
  );
}
