import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";

import { stake, getSnpMasterContract } from "../snp/utils";

const useStake = (pid: number) => {
  const { account } = useWallet();
  const snp = useSnp();

  const handleStake = useCallback(
    async (amount: string, ref: string) => {
      const txHash = await stake(
        getSnpMasterContract(snp),
        pid,
        amount,
        ref,
        account
      );
      console.log(txHash);
    },
    [account, pid, snp]
  );

  return { onStake: handleStake };
};

export default useStake;
