import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";

import { withdrawUSDT, getSVualtUSDTContract } from "../snp/utils";

const useWithdraw = () => {
  const { account } = useWallet();
  const snp = useSnp();
  const snpVaultContract = getSVualtUSDTContract(snp);

  const handleWithdraw = useCallback(
    async (amount: string) => {
      const txHash = await withdrawUSDT(snpVaultContract, amount, account);
      console.log(txHash);
    },
    [account, snpVaultContract]
  );

  return { onWithdraw: handleWithdraw };
};

export default useWithdraw;
