import React from "react";
import styled from "styled-components";
import { isMobile } from 'react-device-detect'
import { useTranslation } from "react-i18next";
import { useWallet } from "use-wallet";

import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import Actions from "./components/Actions";
import usdtlogo from "../../assets/images/usdtlogo.png";
import WalletProviderModal from "../../components/WalletProviderModal";
import useModal from "../../hooks/useModal";

const StyledVault = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;


const Vault: React.FC = () => {
  const { account, connect } = useWallet();
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />);
  const { t } = useTranslation();
  return (
    <Page>
      {!!account ? (
        <>
          <PageHeader
            icon={<img src={usdtlogo} style={{ height: 120 }} />}
            subtitle={t("Deposit or withdraw assets whenever you want")}
            title={t("Dual-mining to aggregate financial for USDT")}
          />
          <StyledVault>
            <Actions />
            <StyledInfo>
              ⭐️ <b>{t("Pro Tip")}</b>: {t("Investment is risky.Use at your own risk.")}
            </StyledInfo>
            <Spacer size="sm" />
          </StyledVault>
        </>
      ) : (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <PageHeader
              icon={<img src={usdtlogo} style={{ height: 120 }} />}
              subtitle={t("Deposit or withdraw assets whenever you want")}
              title={t("Dual-mining to aggregate financial for USDT")}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  isMobile ? connect("injected") : onPresentWalletProviderModal()
                }}
                text={"🔓 " + t("Unlock Wallet")}
              />
            </div>
            <Spacer size="md" />
            <StyledInfo>
              ⭐️ <b>{t("Pro Tip")}</b>: {t("Investment is risky.Use at your own risk.")}
            </StyledInfo>
          </div>
        )}
    </Page>
  );
};

export default Vault;
