import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect'

const Nav: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledNav>
      <StyledLink exact activeClassName="active" to="/">
        {t("Home")}
      </StyledLink>
      <StyledLink activeClassName="active" to="/farms">
        {t("Farm")}
      </StyledLink>
      <StyledLink exact activeClassName="active" to="/vault">
        {t("Vault")}
      </StyledLink>
      {isMobile?(
        <StyledAbsoluteLink
        href="https://www.yuque.com/books/share/7d75d2f8-fc61-4d7a-a0c1-d45b1ed6b6d1?#"
      >{t("Help")}</StyledAbsoluteLink>
      ):(
        <StyledAbsoluteLink
        href="https://www.yuque.com/books/share/7d75d2f8-fc61-4d7a-a0c1-d45b1ed6b6d1?#"
        target="_blank"
      >{t("Help")}</StyledAbsoluteLink>
      )}
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`;

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 18px;
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
  &.active {
    color: ${(props) => props.theme.color.grey[900]};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`;

const StyledAbsoluteLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 18px;
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
  &.active {
    color: ${(props) => props.theme.color.grey[900]};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`;

export default Nav;
