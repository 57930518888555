import React from "react";
import styled from "styled-components";

interface LabelProps {
  text?: string;
}

const Label: React.FC<LabelProps> = ({ text }) => (
  <StyledLabel>{text}</StyledLabel>
);

const StyledLabel = styled.div`
  font-family: "Roboto Mono", monospace;
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 14px;
  font-weight: 600;
`;

export default Label;
