import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";

import { getTotallpSupply, getSnpMasterContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useTotallpSupply = () => {
  const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);
  const block = useBlock();

  const fetchBalance = useCallback(async () => {
    const totalSupply = await getTotallpSupply(snpMasterContract);
    setTotalSupply(new BigNumber(totalSupply));
  }, [ snp,snpMasterContract]);

  useEffect(() => {
    if (snp) {
      fetchBalance();
    }
  }, [ setTotalSupply, block, snp, snpMasterContract]);

  return totalSupply;
};

export default useTotallpSupply;