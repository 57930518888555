import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";

import { getPricePerShare, getSVualtUSDTContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const usePricePerShare = () => {
  const [price, setPricePerShare] = useState(new BigNumber(0));
  const snp = useSnp();
  const vaultContract = getSVualtUSDTContract(snp);
  const block = useBlock();

  const fetchPricePerShare = useCallback(async () => {
    const price = await getPricePerShare(vaultContract);
    setPricePerShare(new BigNumber(price));
  }, [snp,vaultContract]);

  useEffect(() => {
    if (snp) {
      fetchPricePerShare();
    }
  }, [setPricePerShare, block, snp, vaultContract]);

  return price;
};

export default usePricePerShare;
