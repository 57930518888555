import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { Contract } from "web3-eth-contract";

import { getAllowance } from "../utils/erc20";

const useAllowanceEx = (lpContract: Contract, approveAddress: string) => {
  const [allowanceEx, setAllowanceEx] = useState(new BigNumber(0));
  const { account }: { account: string; ethereum: provider } = useWallet();

  const fetchAllowanceEx = useCallback(async () => {
    const allowanceEx = await getAllowance(lpContract, approveAddress, account);
    setAllowanceEx(new BigNumber(allowanceEx));
  }, [account, approveAddress, lpContract]);

  useEffect(() => {
    if (account && lpContract) {
      fetchAllowanceEx();
    }
    let refreshInterval = setInterval(fetchAllowanceEx, 10000);
    return () => clearInterval(refreshInterval);
  }, [account, approveAddress, lpContract]);

  return allowanceEx;
};

export default useAllowanceEx;
