import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";

import { harvest, getSnpMasterContract } from "../snp/utils";

const useHarvest = (pid: number) => {
  const { account } = useWallet();
  const snp = useSnp();
  const snpMasterContract = getSnpMasterContract(snp);

  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(snpMasterContract, pid, account);
    console.log(txHash);
    return txHash;
  }, [account, pid, snpMasterContract]);

  return { onHarvest: handleHarvest };
};

export default useHarvest;
