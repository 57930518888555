import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import useBlock from "./useBlock";
import { client, SNP_PAIR_DATA } from "../apollo/client"

const useSnpPrice = () => {
  const [price, setPrice] = useState(new BigNumber(0));

  const block = useBlock();

  const fetchPrice = useCallback(async () => {
    try {
      //console.log(SNP_PAIR_DATA)
      let query = SNP_PAIR_DATA("0x0b41854f5d251c12b1de6a88dd4292944f04305c");
      let result = await client.query({
        query: query,
        fetchPolicy: 'no-cache'
      })
      result?.data?.pairs
        && setPrice(new BigNumber(result.data.pairs[0].token1Price))

    } catch (e) {
      console.log(e)
    }
  }, []);

  useEffect(() => {
    fetchPrice();
    const interval = setInterval(async () => {
      fetchPrice();
    }, 30000);

    return () => clearInterval(interval);
  }, [setPrice, block]);

  return price;
};

export default useSnpPrice;