import React, { useEffect } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import ModalContent from "../ModalContent";
import ModalTitle from "../ModalTitle";
import Spacer from "../Spacer";
import WalletCard from "./components/WalletCard";

import metamaskLogo from "../../assets/svg/metamask-fox.svg";
import walletConnectLogo from "../../assets/svg/wallet-connect.svg";

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
`;

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, connect } = useWallet();
  const wallet = useWallet();
  const { t } = useTranslation();

  useEffect(() => {
    if (account) {
      onDismiss();
    }
  }, [account, onDismiss]);
  return (
    <Modal>
      <ModalTitle text={t("Select a wallet provider")} />

      <ModalContent>
        <StyledWalletsWrapper>
          <StyledWalletCard>
            <WalletCard
              icon={<img src={metamaskLogo} style={{ height: 32 }} />}
              onConnect={() => connect("injected")}
              title={t("Metamask")}
            />
          </StyledWalletCard>
          <Spacer size="sm" />
          <StyledWalletCard>
            <WalletCard
              icon={<img src={walletConnectLogo} style={{ height: 24 }} />}
              onConnect={() => connect("walletconnect")}
              title="WalletConnect"
            />
          </StyledWalletCard>
        </StyledWalletsWrapper>
      </ModalContent>

      <ModalActions>
        <Button text={t("Cancel")} variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default WalletProviderModal;
