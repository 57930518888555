import { Farm } from "./types";
import seelenicon from "../assets/images/seelen.png";
import susdticon from "../assets/images/susdt.png";
import snpicon from "../assets/images/snp.png";

import { networkID, contractAddresses } from "../constants";

export const OneDay = 86400;
export const stakePools: Farm[] = [
  {
    pid: 6,
    symbol: "SNP",
    name: "SNP(1 and half year)",
    id: "6",
    icon: snpicon,
    contractaddress: contractAddresses.snp[networkID],
    decimals: 18,
    lockPeriod: OneDay * (365+180),
    unlockPeriod: OneDay * 15,
    allocPoint: 250000,
  },
  {
    pid: 5,
    symbol: "sUSDT",
    name: "sUSDT",
    id: "5",
    icon: susdticon,
    contractaddress: contractAddresses.susdt[networkID],
    decimals: 6,
    lockPeriod: 20,
    unlockPeriod: 0,
    allocPoint: 100,
  },
  {
    pid: 0,
    symbol: "Seele",
    name: "Seele(2 Weeks)",
    id: "0",
    icon: seelenicon,
    contractaddress: contractAddresses.seele[networkID],
    decimals: 18,
    lockPeriod: OneDay * 14,
    unlockPeriod: OneDay * 2,
    allocPoint: 1,
  },
  {
    pid: 1,
    symbol: "Seele",
    name: "Seele(1 Month)",
    id: "1",
    icon: seelenicon,
    contractaddress: contractAddresses.seele[networkID],
    decimals: 18,
    lockPeriod: OneDay * 30,
    unlockPeriod: OneDay * 4,
    allocPoint: 1.5,
  },
  {
    pid: 2,
    symbol: "Seele",
    name: "Seele(3 Months)",
    id: "2",
    icon: seelenicon,
    contractaddress: contractAddresses.seele[networkID],
    decimals: 18,
    lockPeriod: OneDay * 90,
    unlockPeriod: OneDay * 7,
    allocPoint: 2.5,
  },
  {
    pid: 3,
    symbol: "Seele",
    name: "Seele(Half Year)",
    id: "3",
    icon: seelenicon,
    contractaddress: contractAddresses.seele[networkID],
    decimals: 18,
    lockPeriod: OneDay * 180,
    unlockPeriod: OneDay * 10,
    allocPoint: 4,
  },
  {
    pid: 4,
    symbol: "Seele",
    name: "Seele(1 Year)",
    id: "4",
    icon: seelenicon,
    contractaddress: contractAddresses.seele[networkID],
    decimals: 18,
    lockPeriod: OneDay * 365,
    unlockPeriod: OneDay * 14,
    allocPoint: 8,
  },
];

export const getFarm = (id: string): Farm => {
  const farm = stakePools.find((farm) => farm.id === id);
  return farm;
};

export const getFarms = (): Farm[] => {
  return stakePools;
};
