import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";

import { getTotalSupply, getSnpContract } from "../snp/utils";
import useSnp from "./useSnp";
import useBlock from "./useBlock";

const useSnpTotalSupply = () => {
  const [supply, setSupply] = useState(new BigNumber(0));
  const snp = useSnp();
  const snpContract = getSnpContract(snp);
  const block = useBlock();

  const fetchSupply = useCallback(async () => {
    const supply = await getTotalSupply(snpContract);
    setSupply(new BigNumber(supply));
  }, [snp,snpContract]);

  useEffect(() => {
    if (snp) {
      fetchSupply();
    }
  }, [setSupply, block, snp, snpContract]);

  return supply;
};

export default useSnpTotalSupply;
